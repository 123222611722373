import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import SEO from '../components/atoms/seo'
import OfficialLayout from '../components/organisms/officialLayout'
import OfficialLowHeader from '../components/organisms/officialLowHeader'

const Wrapper = styled.main`
  padding: 0 0 80px;

  .p-section-inner {
    max-width: 1080px;
    margin: 0 auto;
    padding: 80px 40px 0;

    h3 {
      margin: 0 0 20px;
    }

    p {
      margin: 0 0 20px;
    }

    ul,
    ol {
      margin: 5px 0 20px;

      li {
        margin-bottom: 5px;
        text-indent: -1.15em;
        margin-left: 30px;
      }
    }

    ul {
      li {
        display: list-item;
        list-style: disc inside;

        ul {
          li {
            list-style: circle inside;
            margin-left: 30px;

            ul {
              li {
                list-style: square inside;
              }
            }
          }
        }
      }
    }

    ol {
      li {
        display: list-item;
        list-style: decimal inside;

        ol {
          li {
            margin-left: 30px;
          }
        }

        ul {
          li {
            list-style: disc inside;
          }
        }
      }

      &.parent {
        > li {
          list-style-type: none;
          counter-increment: cnt;

          &:before {
            content: '(' counter(cnt) ') ';
          }
        }
      }
    }
  }
`

type IProps = PageProps<GatsbyTypes.IndexPageQuery>

/**
 * プライバシーポリシー[サービスサイトプライバシーポリシー]
 * @constructor
 */
const PolicyPage = ({ location }: IProps) => (
  <OfficialLayout location={location}>
    <SEO title="アプリケーションプライバシーポリシー" />
    <Wrapper>
      <OfficialLowHeader
        label="アプリケーションプライバシーポリシー"
        description="キンクラ"
      />
      <div className="p-section-inner">
        CLINKS株式会社（以下、「当社」という）は、「キンクラ（勤怠管理サービスの名称）」（以下、「本サービス」という）を提供するにあたり、お客様の個人情報の安全管理、適切な保護を実施いたします。
        <br />
        以下に当社の個人情報保方針を掲げます。
      </div>
      {/** 1.  基本事項 */}
      <div className="p-section-inner">
        <h3>1. 基本事項</h3>
        <ol className="parent">
          <li>
            当社が個人情報を直接的に収集する場合は、事前に個人情報利用目的を明示し、ご本人の同意をいただいた上で収集します。
            <br />
            また、不正な手段での収集は一切行いません。
          </li>
          <li>
            個人情報への不正アクセスまたはその滅失・き損及び漏洩等のリスクに対しては、合理的な安全対策及び予防・是正処置を講じ、事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。
          </li>
          <li>個人情報保護に関する法令およびその他の規範を遵守します</li>
        </ol>
      </div>
      {/** 2. 利用目的 */}
      <div className="p-section-inner">
        <h3>2. 利用目的</h3>
        <ol className="parent">
          <li>
            本サービス利用企業の社員情報
            <ul>
              <li>本サービスのサービス提供のため</li>
              <li>本サービスに関する配信やお知らせ等のご案内のため</li>
              <li>機能改善、新機能追加、新サービスの開発のため</li>
              <li>利用に関する動向調査、不具合調査のため</li>
              <li>広告、及びマーケティングのため</li>
            </ul>
          </li>
          <li>
            本サービス利用企業の担当者情報
            <ul>
              <li>本サービスのサービス提供のため</li>
              <li>本サービスに関する配信やお知らせ等のご案内のため</li>
              <li>機能改善、新機能追加、新サービスの開発のため</li>
              <li>利用に関する動向調査、不具合調査のため</li>
              <li>広告、及びマーケティングのため</li>
              <li>お問い合わせ対応のため</li>
              <li>
                キャンペーン、アンケートの実施、及び商品、景品の発送のため
              </li>
            </ul>
          </li>
          <li>
            本サービスへの問い合わせ・資料ダウンロードで取得する個人情報
            <ul>
              <li>本サービスのサービス提供のため</li>
              <li>問い合わせ内容に対応、回答するため</li>
              <li>
                本サービスを含む当社のサービスに関する広告やご案内、資料送付のため
              </li>
              <li>
                個人として特定できない形で統計情報として集計し、資料とするため
              </li>
            </ul>
          </li>
        </ol>
      </div>
      {/** 3. 取得する個人情報 */}
      <div className="p-section-inner">
        <h3>3. 取得する個人情報</h3>
        <ol className="parent">
          <li>
            本サービス利用企業の社員情報
            <br />
            お客様が本サービスにご登録いただく会社名、氏名、性別、メールアドレス、役職、入社日、生年月日、雇用形態、電話番号、住所、健保被保険者番号、雇用保険被保険者番号、勤怠情報等の情報
          </li>
          <li>
            お問い合わせ情報
            <br />
            お問い合わせ入力フォームへ記載いただく会社名、氏名、メールアドレス、お問い合わせ内容等の情報、当社から追加で依頼する確認事項等
          </li>
          <li>
            端末及び接続情報
            <br />
            お客様が本サービスを利用される際の端末機種名、OSバージョン、ブラウザ情報、広告識別子、IPアドレス、通信キャリア等の情報
          </li>
          <li>
            キャペーン等の応募情報
            <br />
            当社が実施するキャンペーンやアンケート等の入力フォームに記載いただく情報
          </li>
        </ol>
      </div>
      {/** 4. 個人情報の提供 */}
      <div className="p-section-inner">
        <h3>4. 個人情報の提供</h3>
        <p>
          当社は以下の場合を除き、お客様の個人情報を第三者に提供することはありません。
        </p>
        <ol className="parent">
          <li>ご本人の同意がある場合</li>
          <li>
            当社が処理する業務を当社の指定する外部の委託先に対して、その処理を委託するために当該社内個人情報を預託する場合。
          </li>
          <li>
            当社が特定の業務目的のために適法な範囲において、提供または預託することを必要と判断した場合。
          </li>
          <li>法令または行政機関により適法に開示を要求され、提供する場合。</li>
          <li>
            情報主体又は公衆の生命、健康、財産などの重大な利益を保護する為に必要な場合。
          </li>
        </ol>
      </div>
      {/** 5．Cookie等の取り扱い */}
      <div className="p-section-inner">
        <h3>5. Cookie等の取り扱い</h3>
        <ol className="parent">
          <li>
            Google Analytics
            <br />
            当社は、お客様が当社サイトをどのように使用されているかを測定し、サービスを改善する為、Google
            Analyticsを利用しています。これらのツールではデータ収集の為にCookieを利用しています。どのような情報が収集・処理されるかについては以下のページからご確認いただけます。なお、この収集されるデータは匿名で収集されており、個人を特定するものではありません。
            <br />
            <a
              href="https://policies.google.com/technologies/partner-sites?hl=ja"
              target="_blank"
              rel="noreferrer"
            >
              Google のサービスを使用するサイトやアプリから収集した情報の Google
              による使用
            </a>
            <br />
            <br />
            Google
            Analyticsを無効にされたい場合は、以下よりアドオンをインストールし、ブラウザのアドオン設定を変更する事で無効にすることができます。
            <br />
            <a
              href="https://tools.google.com/dlpage/gaoptout?hl=ja"
              target="_blank"
              rel="noreferrer"
            >
              Google アナリティクス オプトアウト アドオン
            </a>
            <br />
            <br />
          </li>
          <li>
            オンライン広告
            <br />
            当社サイトをご利用されたお客様のアクセス情報に基づき、Google、Yahoo!を含む第三者配信事業者を介して、インターネット上のサイトに広告が配信されます。この第三者配信事業者はお客様のCookieを使用します。第三者配信事業者によるCookieの使用は、以下のサイトから無効にできます。
            <br />
            <a
              href="https://support.google.com/ads/answer/2662922?hl=ja"
              target="_blank"
              rel="noreferrer"
            >
              Google広告
            </a>
            <br />
            <a
              href="https://btoptout.yahoo.co.jp/optout/"
              target="_blank"
              rel="noreferrer"
            >
              Yahoo! 広告
            </a>
          </li>
        </ol>
      </div>
      {/** 6．個人情報の訂正、開示請求、利用停止 */}
      <div className="p-section-inner">
        <h3>6. 個人情報の訂正、開示請求、利用停止</h3>
        <p>
          お客様ご本人の個人情報の訂正、開示、利用停止を希望する場合は、第6項に定める問い合わせ先（Eメール）に連絡することにより、個人情報の訂正、開示、利用停止を求めることができます。
          <br />
          お客様から求めを受けた場合は、速やかに対応致します。
          <br />
          ただしお客様とご連絡が確認とれないなどの場合、個人情報の訂正、開示、利用停止が対応できない場合がございます。
        </p>
      </div>
      {/** 7、お問い合わせ先 */}
      <div className="p-section-inner">
        <h3>7. お問い合わせ先</h3>
        <p>
          お客様の個人情報に関するお問い合わせにつきましては、以下の窓口で対応致します。
          <br />
          お問い合わせの際は、ご本人であることを確認させていただく場合がございます。
          <br />
          個人情報に関するお問い合わせ以外は対応しかねます。
          <br />
          ※本サービス利用企業の社員であるご本人様からの、登録情報に関する開示等の求めには応じかねます。利用企業様のご担当者様までお問合せください。
        </p>
      </div>
      {/** お問い合わせ先 */}
      <div className="p-section-inner">
        <p>
          【個人情報の取り扱いに関するお問い合わせ先】
          <br />
          〒104-0032 東京都中央区八丁堀1-10-7 TMG八丁堀ビル10F
          <br />
          連絡先：TEL：03-6262-8135 （受付時間：平日10:00〜18:00）
          <br />
          FAX：03-6262-8136
          <br /> Email：info@clinks.jp <br />
          個人情報保護管理者 小林 裕輔
        </p>
      </div>
      {/** 制定日 */}
      <div className="p-section-inner">
        <p>
          2024年10月28日 改定
          <br />
          2022年5月18日 改定
          <br />
          2021年10月27日 制定
        </p>
      </div>
    </Wrapper>
  </OfficialLayout>
)

export default PolicyPage

export const pageQuery = graphql`
  query PolicyPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
